<template>
  <div class="medicalOrders h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.history.Label }}
      </h3>
      <div
        class="
          row row-wrapper
          d-flex
          align-items-center
          justify-content-center
          pb-5
        "
      >
        <div class="row pt-md-4">
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
            v-if="this.$route.name == 'step4'"
          >
            <div>
              <div
                class="card"
                v-for="question in this.history.questions"
                :key="question.id"
              >
                <div class="card-header" :id="`heading` + question.id">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed-btn"
                      :data-bs-target="'#id' + question.id"
                      :aria-expanded="expanded"
                      @click="openQues(question)"
                    >
                      {{ question.question }}
                      <i class="fas fa-plus" :id="'icon' + question.id"></i>
                    </button>
                  </h5>
                </div>

                <div :id="`id` + question.id" class="collapse">
                  <div class="card-body">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
            v-else-if="this.$route.name == 'step5'"
          >
            <div>
              <div
                class="card"
                v-for="question in this.history.questions"
                :key="question.id"
              >
                <div class="card-header" :id="`heading` + question.id">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed-btn"
                      :data-bs-target="'#id' + question.id"
                      :aria-expanded="expanded"
                      @click="openQues(question)"
                    >
                      {{ question.question }}
                      <i class="fas fa-plus" :id="'icon' + question.id"></i>
                    </button>
                  </h5>
                </div>

                <div :id="`id` + question.id" class="collapse">
                  <div class="card-body">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
            v-else-if="this.$route.name == 'step6'"
          >
            <div>
              <div
                class="card"
                v-for="question in this.history.questions"
                :key="question.id"
              >
                <div class="card-header" :id="`heading` + question.id">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed-btn"
                      :data-bs-target="'#id' + question.id"
                      :aria-expanded="expanded"
                      @click="openQues(question)"
                    >
                      {{ question.question }}
                      <i class="fas fa-plus" :id="'icon' + question.id"></i>
                    </button>
                  </h5>
                </div>

                <div :id="`id` + question.id" class="collapse">
                  <div class="card-body">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
            v-else-if="this.$route.name == 'step7'"
          >
            <div>
              <div
                class="card"
                v-for="question in this.history.questions"
                :key="question.id"
              >
                <div class="card-header" :id="`heading` + question.id">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed-btn"
                      :data-bs-target="'#id' + question.id"
                      :aria-expanded="expanded"
                      @click="openQues(question)"
                    >
                      {{ question.question }}
                      <i class="fas fa-plus" :id="'icon' + question.id"></i>
                    </button>
                  </h5>
                </div>

                <div :id="`id` + question.id" class="collapse">
                  <div class="card-body">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
            v-else-if="this.$route.name == 'step8'"
          >
            <div>
              <div
                class="card"
                v-for="question in this.history.questions"
                :key="question.id"
              >
                <div class="card-header" :id="`heading` + question.id">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed-btn"
                      :data-bs-target="'#id' + question.id"
                      :aria-expanded="expanded"
                      @click="openQues(question)"
                    >
                      {{ question.question }}
                      <i class="fas fa-plus" :id="'icon' + question.id"></i>
                    </button>
                  </h5>
                </div>

                <div :id="`id` + question.id" class="collapse">
                  <div class="card-body">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
            v-else-if="this.$route.name == 'step9'"
          >
            <div>
              <div
                class="card"
                v-for="question in this.history.questions"
                :key="question.id"
              >
                <div class="card-header" :id="`heading` + question.id">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed-btn"
                      :data-bs-target="'#id' + question.id"
                      :aria-expanded="expanded"
                      @click="openQues(question)"
                    >
                      {{ question.question }}
                      <i class="fas fa-plus" :id="'icon' + question.id"></i>
                    </button>
                  </h5>
                </div>

                <div :id="`id` + question.id" class="collapse">
                  <div class="card-body">
                    {{ question.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 pb-5 pb-md-5"
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
          >
            <img
              src="@/assets/images/patient_history.jpg"
              alt=""
              class="w-100 rounded"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/store";
export default {
  name: "HistoryTaking",
  data() {
    return {
      history: [],
      selected: [],
    };
  },
  updated() {
    if (this.$route.name == "step4") {
      this.history = this.$store.getters["presentHistory"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['presentHistory'].instructions )
    } else if (this.$route.name == "step5") {
      this.history = this.$store.getters["pastHistory"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['pastHistory'].instructions )
    } else if (this.$route.name == "step6") {
      this.history = this.$store.getters["familyHistory"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['familyHistory'].instructions )
    } else if (this.$route.name == "step7") {
      this.history = this.$store.getters["menstrualHistory"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['menstrualHistory'].instructions )
    } else if (this.$route.name == "step8") {
      this.history = this.$store.getters["obstetricHistory"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['obstetricHistory'].instructions )
    } else if (this.$route.name == "step9") {
      this.history = this.$store.getters["familyPlanningHistory"];
      this.$store.dispatch( 'setInstructions', this.$store.getters['familyPlanningHistory'].instructions )
    }
  },
  methods: {
    openQues(question) {
      if ($(`#id${question.id}`).hasClass("show")) {
        $(`#icon${question.id}`).removeClass('fa-minus');
        $(`#icon${question.id}`).addClass('fa-plus');
        $(`#id${question.id}`).collapse("hide");
      } else {
        this.selected.push(question);
        $(`#icon${question.id}`).removeClass('fa-plus');
        $(`#icon${question.id}`).addClass('fa-minus');
        $(`#id${question.id}`).collapse("show");
      }
    },
  },
  mounted() {
    this.history = this.$store.getters["presentHistory"];
    this.$store.dispatch( 'setStartTime');
    // this.$store.dispatch('prevNavigationStatus', false);
  },
  beforeRouteEnter (to, from, next) {
    if (to.name == "step4") {
      if(store.getters["presentHistory"].case_histories.length <= 0 ){
        if(from.name.substr(4) > 4){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step5") {
      if(store.getters["pastHistory"].case_histories.length <= 0){
        if(from.name.substr(4) > 5){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step6") {
      if(store.getters["familyHistory"].case_histories.length <= 0){
        if(from.name.substr(4) > 6){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step7") {
      if(store.getters["menstrualHistory"].case_histories.length <= 0){
        if(from.name.substr(4) > 7){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step8") {
      if(store.getters["obstetricHistory"].case_histories.length <= 0){
        if(from.name.substr(4) > 8){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    } else if (to.name == "step9") {
      if(store.getters["familyPlanningHistory"].case_histories.length <= 0){
        if(from.name.substr(4) > 9){
          store.state.step = store.state.step - 1
        } else{
          store.state.step = store.state.step + 1
        }
        next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
      }
      else{
        next()
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$route.name == "step4") {
      this.$store.dispatch( 'setEndTime' , 'presen tHistory')
    } else if (this.$route.name == "step5") {
      this.$store.dispatch( 'setEndTime' , 'past History')
    } else if (this.$route.name == "step6") {
      this.$store.dispatch( 'setEndTime' , 'family History')
    }
    // this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style scoped>
</style>